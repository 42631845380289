/**
 * 404.jsx
 *
 * @file 404 page of the web application.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { GatsbySeo } from 'gatsby-plugin-next-seo'
import React from 'react'
import Typography from '@mui/material/Typography'

// internal imports
import { Link } from '../components/Link'
import routes from '../data/routes.json'

/**
 * This component represents the 404 page of this application.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered page.
 */
const NotFoundPage = () => {

	return (
		<>
			<GatsbySeo title="404 – Seite nicht gefunden" />
			<Typography align="center" color="primary" paragraph variant="h4">404 - Seite nicht gefunden</Typography>

			<Typography paragraph variant="body2">Die von Ihnen angeforderte Seite konnte nicht gefunden werden. <Link to={ routes[ 0 ].path }>Zurück zur Startseite</Link></Typography>
		</>
	)
}

export default NotFoundPage
